import styled from '@emotion/styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import useStreamingVideo, { type HlsConfig } from '../../../utils/hooks/useStreamingVideo';
import { colors, spacing } from '../../../utils/styleguide';
import { addBreadcrumb } from '../../../utils/tracking/sentry/sentry';
import Icon from '../Icon';
import { PlayButton } from './Play';

export { HlsConfig };

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
`;

const FullVideo = styled.video`
  object-fit: cover;
  width: 100%;
`;

const VolumeButton = styled.button`
  position: absolute;
  right: ${spacing[2]}px;
  bottom: ${spacing[2]}px;
  border: none;
  width: ${spacing[7]}px;
  height: ${spacing[7]}px;
  display: flex;
  border-radius: ${spacing[4]}px;
  background-color: ${colors.black};
  justify-content: center;
  align-items: center;
  outline: none;

  :hover {
    background-color: #2c2e3a;
  }

  :focus {
    box-shadow: 0 0 0 2px ${colors.yellow}, 0 0 0 3px ${colors.black};
  }
`;

type Props = {
  src: string;
  posterSrc?: string;
  shouldPlay?: boolean;
  hideMute?: boolean;
  hlsConfig?: Partial<HlsConfig>;
  showControls?: boolean;
};

export default function VideoPlayer({
  src,
  posterSrc,
  shouldPlay,
  hideMute,
  hlsConfig,
  showControls = true,
}: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const controlQueue = useRef(Promise.resolve());

  const { play, pause, doneLoading } = useStreamingVideo({ src, ref: videoRef, hlsConfig });

  const playVideo = useCallback(() => {
    controlQueue.current = controlQueue.current.then(() => {
      addBreadcrumb({
        category: 'video',
        message: 'Attempt to play video',
        level: 'info',
      });

      return play();
    });
  }, [play]);

  useEffect(() => {
    if (!doneLoading) {
      return;
    }

    if (shouldPlay) {
      playVideo();
    } else {
      controlQueue.current = controlQueue.current.then(() => {
        addBreadcrumb({
          category: 'video',
          message: 'Attempt to pause video',
          level: 'info',
        });
        pause();
      });
    }
  }, [shouldPlay, playVideo, doneLoading, pause]);

  useEffect(() => {
    let hidden = '';
    let visibilityChange = '';

    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      // @ts-expect-error FIXME: Property 'msHidden' does not exist on type 'Document'. Did you mean 'hidden'?
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      // @ts-expect-error FIXME: Property 'webkitHidden' does not exist on type 'Document'. Did you mean 'hidden'?
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    const handleVisibilityChange = () => {
      // Start playing if auto-paused
      // @ts-expect-error FIXME: No index signature with a parameter of type 'string' was found on type 'Document'.
      if (!document[hidden] && shouldPlay) {
        playVideo();
      }
    };

    document.addEventListener(visibilityChange, handleVisibilityChange);

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, [shouldPlay, playVideo]);

  function toggleMuted() {
    setMuted((muted) => !muted);
  }

  useEffect(() => {
    const videoElement = videoRef.current;
    const setPlaying = () => setIsPlaying(true);
    const setNotPlaying = () => setIsPlaying(false);
    if (videoElement) {
      videoElement.addEventListener('play', setPlaying);
      videoElement.addEventListener('pause', setNotPlaying);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', setPlaying);
        videoElement.removeEventListener('pause', setNotPlaying);
      }
    };
  }, [videoRef]);

  return (
    <Wrapper>
      <FullVideo
        crossOrigin="anonymous"
        ref={videoRef}
        muted={muted}
        loop
        autoPlay
        playsInline
        poster={posterSrc}
        src={src}
      />
      {!isPlaying && showControls && (
        <PlayButton
          onClick={() => {
            setIsPlaying(true);
            if (shouldPlay) {
              playVideo();
              setMuted(false);
            }
          }}
        />
      )}
      {!hideMute && (
        <VolumeButton onClick={toggleMuted}>
          {muted ? (
            <Icon name="volume-mute" size={24} stroke="white" />
          ) : (
            <Icon name="volume-on" size={24} stroke="white" />
          )}
        </VolumeButton>
      )}
    </Wrapper>
  );
}
