import { RefObject, useEffect } from 'react';
import isBrowser from './helpers/isBrowser';

const useIsVisible = ({
  ref,
  onIntersecting,
  options,
}: {
  ref: RefObject<Element>;
  onIntersecting: (param: { isIntersecting: boolean }) => void;
  options?: IntersectionObserverInit;
}) => {
  useEffect(() => {
    if (window.IntersectionObserver) {
      const observer = new IntersectionObserver(
        (entry) => {
          onIntersecting({ isIntersecting: entry[0].isIntersecting });
        },
        options || {
          rootMargin: '0px',
          threshold: 0.0,
        },
      );
      if (ref.current) observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [onIntersecting, options, ref]);
  return { hasSupport: !!(isBrowser() && window.IntersectionObserver) };
};

export default useIsVisible;
