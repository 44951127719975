import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Video } from '../../utils/api/videoApi';
import { VIDEO_ORIENTATIONS, getStreamingSrc } from '../../utils/helpers/video';
import { ImageStyleProps } from '../contentful-elements/Image';
import BackgroundVideo from './media/BackgroundVideo';
import PropsComponent from './types/PropsComponent';

type SectionProps = {
  backgroundColor?: string;
  backgroundLinear?: string;
  overlay?: number;
};

const Section = styled.section<SectionProps>`
  background-color: ${({ backgroundColor, overlay = 0 }: SectionProps) =>
    backgroundColor || `rgba(0,0,0,${overlay / 100})`};
  background-image: ${({ backgroundLinear }: SectionProps) => backgroundLinear};
  background-size: cover;
  position: relative;
  width: 100%;
`;

const BackgroundImageStyles = css`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
`;

type Props = {
  id?: string;
  Image?: PropsComponent<ImageStyleProps>;
  backgroundColor?: string;
  backgroundLinear?: string;
  children?: ReactNode;
  overlay?: number;
  landscapeBackgroundVideo?: Video;
  portraitBackgroundVideo?: Video;
};

const HeadlinerBackground = ({
  id,
  Image,
  backgroundColor,
  backgroundLinear,
  children,
  overlay,
  landscapeBackgroundVideo,
  portraitBackgroundVideo,
}: Props) => {
  if (!portraitBackgroundVideo || !landscapeBackgroundVideo) {
    return (
      <Section
        id={id}
        backgroundColor={backgroundColor}
        backgroundLinear={backgroundLinear}
        overlay={overlay}
      >
        {Image && (
          <Image
            css={BackgroundImageStyles}
            aspectRatios={{ mobile: 9 / 16, tablet: 16 / 9 }}
            lazyLoad
          />
        )}
        {children}
      </Section>
    );
  }
  return (
    <BackgroundVideo
      videoLargeScreenUrl={getStreamingSrc({
        video: landscapeBackgroundVideo,
        orientation: VIDEO_ORIENTATIONS.LANDSCAPE,
      })}
      videoSmallScreenUrl={getStreamingSrc({
        video: portraitBackgroundVideo,
        orientation: VIDEO_ORIENTATIONS.PORTRAIT,
      })}
      posterLargeScreenUrl={landscapeBackgroundVideo.metaData?.posterSrc}
      posterSmallScreenUrl={portraitBackgroundVideo.metaData?.posterSrc}
      aspectRatios={{ desktop: 0, tablet: 0, mobile: 0 }}
      showControls={false}
      hasOverlay
      hlsConfig={{
        capLevelToPlayerSize: false,
      }}
    >
      {children}
    </BackgroundVideo>
  );
};

export default HeadlinerBackground;
